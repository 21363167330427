import { TDegreeInterest } from "../types/common";

export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any = [
    { "text": "General Computers & IT", "value": "400" },
    { "text": "Coding Bootcamps", "value": "401" },
    { "text": "Computer Programming", "value": "402" },
    { "text": "Data Science & Analytics", "value": "403" },
    { "text": "Game Art Development", "value": "404" },
    { "text": "Information Systems", "value": "405" },
    { "text": "Information Technology", "value": "406" },
    { "text": "IT Project Managment", "value": "407" },
    { "text": "Network Security", "value": "408" },
    { "text": "Product and Graphic Design", "value": "409" },
    { "text": "Software Engineering", "value": "410" },
    { "text": "Web Development", "value": "411" },
    { "text": "CyberSecurity", "value": "412" }
]



export const areaStudyOptions: any = [
    {
        "category_id": "3",
        "category_name": "Business"
    }
]


