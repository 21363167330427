import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SEO_API } from '../common/url';
import { getSEOConfigResponse } from '../Service';
import { WebContext } from '../contextProvider/webContextProvider';

const SeoHandler = () => {
  const { webContent, setWebContent } = useContext(WebContext);
  // State for seoData
  const [seoData, setSeoData] = useState<any>({});
  // Get base URL
  const baseUrl = window.location.origin;

  useEffect(() => {
    // getSEOConfig()
  }, [])


  const getSEOConfig = async () => {
    try {
      const response = await getSEOConfigResponse(`${SEO_API}?id=43d9f6b4-7ec5-4543-9169-364c06a879de`);
      console.log('response', response);
      setSeoData(response)
    }
    catch (error) {
      console.log('response', error);

    }
  }


  return (
    <Helmet>
      {/* Charset */}
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href={webContent?.defaultData?.favIcon} />
      <meta name="author" content="Compare Online Degrees" />

      {/* Title Tag */}
      {<title>{webContent?.seoData?.seoTitle}</title>}

      {/* Keywords */}
      {webContent?.seoData?.seoKeywords && (
        <meta name="keywords" content={webContent?.seoData?.seoKeywords}></meta>
      )}

      {/* Meta Description */}
      {webContent?.seoData.seoDescription && (
        <meta name="description" content={webContent?.seoData.seoDescription}></meta>
      )}

      {/* Canonical URL */}
      <link rel="canonical" href={webContent?.seoData?.seoCanonicalUrl} />

      {/* Robots Meta Tag (controls search engine behavior) */}
      <meta name="robots" content="index" />

      {/* Open Graph (for Social Media Sharing) */}

      {webContent?.seoData?.ogType && (
      <meta property="og:type" content={webContent?.seoData?.ogType} />
      )}

      {webContent?.seoData?.ogLocale && (
            <meta property="og:locale" content={webContent?.seoData?.ogLocale} />
      )}
      
      <meta property="og:site_name" content="Compare Online Degree" />

      {webContent?.seoData?.seoTitle && (
        <meta property="og:title" content={webContent?.seoData.seoTitle} />
      )}

      {webContent?.seoData?.seoDescription && (
        <meta property="og:description" content={webContent?.seoData.seoDescription} />
      )}

      {webContent?.seoData?.seoImage && (
        <meta property="og:image" content={webContent?.seoData.seoImage} />
      )}

      <meta property="og:url" content={webContent?.seoData?.seoCanonicalUrl || baseUrl} />

      {/* Twitter Card (for Twitter Sharing) */}
      {webContent?.seoData?.twitterCard && (
        <meta name="twitter:card" content={webContent?.seoData?.twitterCard} />
      )}

      {webContent?.seoData?.twitterTitle && (
        <meta name="twitter:title" content={webContent?.seoData?.twitterTitle} />
      )}

      {webContent?.seoData?.twitterDescription && (
        <meta
          name="twitter:description"
          content={webContent?.seoData?.twitterDescription}
        />
      )}
      {webContent?.seoData?.twitterSite && (
        <meta property="twitter:site" content={webContent?.seoData?.twitterSite} />
      )}
      {webContent?.seoData?.twitterImage && (
        <meta name="twitter:image" content={webContent?.seoData?.twitterImage} />
      )}

      {/* Hreflang Tags */}
      <link rel="alternate" href={baseUrl} hrefLang="en" />
    </Helmet>
  );
};

export default SeoHandler;
