import FormIndex from "../components/LeadFormSteps/FormIndex";
import ComputerListing from "../Container";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: ComputerListing
    },
    {
        name: "General Computers & IT",
        path: "/computers&it/generalcomputers&it",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Coding Bootcamps",
        path: "/computers&it/codingbootcamps",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Computer Programming",
        path: "/computers&it/computerprogramming",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Data Science & Analytics",
        path: "/computers&it/datascience&analytics",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Game Art Development",
        path: "/computers&it/gameartdevelopment",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Information Systems",
        path: "/computers&it/informationsystems",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Information Technology",
        path: "/computers&it/informationtechnology",
        exact: true,
        component: ComputerListing
    },
    {
        name: "IT Project Managment",
        path: "/computers&it/itprojectmanagment",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Network Security",
        path: "/computers&it/networksecurity",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Product and Graphic Design",
        path: "/computers&it/productandgraphicdesign",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Software Engineering",
        path: "/computers&it/softwareengineering",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Web Development",
        path: "/computers&it/webdevelopment",
        exact: true,
        component: ComputerListing
    },
    {
        name: "CyberSecurity",
        path: "/computers&it/cybersecurity",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Business",
        path: "/business/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Criminal Justice",
        path: "/criminal-justice/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Psychology",
        path: "/psychology/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Computers & IT",
        path: "/computers-&-technology/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Healthcare",
        path: "/health,-nursing,-medicine/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Education & Teaching",
        path: "/education-&-teaching/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Vocational Training",
        path: "/vocational-training/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Liberal Arts",
        path: "/art-&-architecture/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "General",
        path: "/general/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "Nursing",
        path: "/nursing/:id?",
        exact: true,
        component: ComputerListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: ComputerListing
    },

]
